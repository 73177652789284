<template>
  <OneColumnPage>
    <template v-if="loading">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>
    <template v-else-if="error">
      <v-alert type="error">
        An error occurred.
      </v-alert>
    </template>
    <template v-else-if="activationCompleted">
      <v-alert type="success">
        Account activation successful.
      </v-alert>
      <v-btn color="primary" v-if="!isAuthenticated" to="/login">
        Sign in
      </v-btn>
    </template>
  </OneColumnPage>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState("signup", ["activationCompleted", "error", "loading"])
  },
  methods: mapActions("signup", ["activateAccount", "clearActivationStatus"]),
  created() {
    this.activateAccount(this.$route.params);
  },
  beforeRouteLeave(to, from, next) {
    this.clearActivationStatus();
    next();
  }
};
</script>
