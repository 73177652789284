<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12 pb-2">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-subtitle>
            New here?
            <router-link to="/register">Register</router-link>
          </v-card-subtitle>
          <v-card-text>
            <v-form
              @submit.prevent="submit"
              @keyup.native.enter="login(inputs)"
            >
              <v-text-field
                v-model="inputs.email"
                id="email"
                label="Email"
                prepend-icon="mdi-email"
                type="text"
                :error="formErrors.email"
                :error-messages="formErrors.email"
              />
              <v-text-field
                v-model="inputs.password"
                id="password"
                label="Password"
                prepend-icon="mdi-lock"
                type="password"
                :error="formErrors.password"
                :error-messages="formErrors.password"
              />
              <v-alert
                outlined
                type="error"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                {{ error }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn to="/password_reset" text>Reset password</v-btn>
            <v-spacer />
            <v-btn
              :loading="authenticating"
              color="primary"
              @click="login(inputs)"
              >Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      inputs: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    login({ email, password }) {
      this.$store.dispatch("auth/login", { email, password }).then(() => {
        // if there is no error go to home page
        if (!this.$store.state.auth.error) {
          this.$store.dispatch("auth/getAccountDetails").then(() => {
            // Request notification permission after successful sign-in
            if (Notification.permission !== "granted") {
              Notification.requestPermission().then(function(result) {
                if (result === "granted") {
                  console.log("Permission for notifications was granted");
                  // Further notification setup can be done here
                }
              });
            }
            let query = this.$route.query;
            if (query.from) {
              let path = query.from;
              delete query.from;
              this.$router.push({ path, query });
            } else this.$router.push("/groups");
          });
        }
      });
    }
  },
  computed: mapState({
    authenticating: (state) => state.auth.authenticating,
    formErrors: (state) => {
      if (state.auth.error) {
        try {
          return state.auth.error.response.data;
        } catch (err) {
          return {
            non_field_errors: [
              "Oops! Our hamsters running the server are tired. Give them a moment and try again later."
            ]
          };
        }
      } else {
        return {};
      }
    }
  })
};
</script>
