<template>
  <OneColumnPage>
    <v-card :loading="!game" class="mb-2">
      <v-skeleton-loader
        type="card-heading, list-item-three-line"
        class="mx-auto"
        :loading="!game"
      >
        <v-card-title class="ml-2">{{ game.title }}</v-card-title>
        <v-card-text class="ml-2 body-1 text--secondary">{{
          game.description
        }}</v-card-text>
        <v-expansion-panels accordion flat hover tile>
          <v-expansion-panel>
            <v-expansion-panel-header class="secondary  text--secondary">
              <!-- <v-expansion-panel-header v-slot="{ open }"> -->
              {{
                game.recurrences
                  ? "Every " + this.$moment(game.date).format("dddd")
                  : this.$moment(game.date).format("ddd MMMM D")
              }}, {{ [game.start, "HH:mm:ss", "HH:mm"] | moment("h:mm a") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item
                  :to="{ name: 'GroupDetail', params: { group_id } }"
                  exact
                >
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-account-group</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ game.group && game.group.title }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{
                      game.recurrences
                        ? "Every " + this.$moment(game.date).format("dddd")
                        : this.$moment(game.date).format("dddd, MMMM D")
                    }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-clock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ [game.start, "HH:mm:ss", "HH:mm"] | moment("h:mm a") }}
                    -
                    {{ [game.end, "HH:mm:ss", "HH:mm"] | moment("h:mm a") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="game.location">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-map</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ game.location }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="game.max_players">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-account-multiple-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ game.max_players }} players max
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-actions>
          <v-btn
            background-color="transparent"
            text
            exact
            :to="{ name: 'GroupDetail', params: { group_id } }"
          >
            {{ isMobile ? "Back" : "Back to Group" }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="sendSignup({ game, status: '' })"
            :loading="signupLoading"
            v-if="!['confirmed', 'waitlisted'].includes(game.user_status)"
          >
            {{ game.user_can_sign_up ? "Sign Up" : "Join Waitlist" }}
          </v-btn>

          <v-btn
            text
            color="warning"
            @click="sendSignup({ game, status: 'maybe' })"
            :loading="signupLoading"
            v-if="game.user_status != 'maybe'"
          >
            Maybe
          </v-btn>
          <v-btn
            text
            color="error"
            @click="sendSignup({ game, status: 'out' })"
            :loading="signupLoading"
            v-if="game.user_status != 'out'"
          >
            Out
          </v-btn>

          <v-btn
            text
            color="success"
            @click="sendSignup({ game, status: 'add_guest' })"
            :loading="signupLoading"
            v-if="
              (['confirmed', 'waitlisted'].includes(game.user_status) ||
                group.user_is_admin) &&
                game.user_guests_left > 0
            "
          >
            Add +1
          </v-btn>
          <v-btn
            text
            @click="sendSignup({ game, status: 'remove_guest' })"
            :loading="signupLoading"
            v-if="game.user_guests.length > 0"
          >
            Remove +1
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>

    <v-alert
      v-for="(signup, index) in userSignups"
      :key="index"
      :color="statusColors[signup.status]"
      :icon="statusIcons[signup.status]"
      class="my-2"
    >
      <v-row>
        <v-col cols="12" sm="5" class="flex-sm-shrink-1">
          {{
            signup.guest
              ? signup.guest_name
                ? signup.guest_name + " is "
                : "Your guest is "
              : "You are "
          }}
          <strong>{{ signup.status.toUpperCase() }}</strong>
        </v-col>
        <v-col v-if="signup.guest">
          <v-row class="justify-end">
            <v-col class="shrink py-1">
              <v-dialog v-model="signup.settingGuestName" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text>
                    Set guest name
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span></span>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="signup.guest_name"
                      label="Guest Name"
                      outlined
                      dense
                      autofocus
                      maxlength="100"
                      hide-details="auto"
                      @keyup="signup.modified = true"
                      @keyup.enter="
                        setGuestName(signup).then(
                          (signup.settingGuestName = false)
                        )
                      "
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="setGuestName(signup)"
                      >Submit</v-btn
                    >
                    <v-btn
                      color="secondary"
                      text
                      small
                      @click="
                        signup.modified
                          ? fetchGameDetail(game_id).then(
                              (signup.settingGuestName = false)
                            )
                          : (signup.settingGuestName = false)
                      "
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col class="shrink py-1 ">
              <v-btn
                text
                @click="sendSignup({ game, status: 'remove_guest', signup })"
                v-if="game.user_guests.length > 1 && signup.guest"
              >
                Remove</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-alert>

    <v-tabs
      background-color="secondary"
      class="elevation-2"
      centered
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#comments">
        Comments
        <v-badge
          overlap
          offset-x="6"
          color="primary"
          :content="comments.count"
          :value="!!comments.count"
        >
          <v-icon>mdi-comment-text</v-icon>
        </v-badge>
      </v-tab>
      <v-tab href="#signups">
        Signups
        <v-badge
          overlap
          offset-x="6"
          color="primary"
          :content="
            waitlistedCount
              ? signedUpCount + ' + ' + waitlistedCount
              : signedUpCount
          "
          :value="!!signedUpCount"
        >
          <v-icon>mdi-account-multiple-check</v-icon>
        </v-badge>
      </v-tab>

      <v-tab-item value="comments">
        <Comments
          v-if="game.id === parseInt(game_id)"
          object_type="game"
          :object_pk="game_id"
        />
      </v-tab-item>

      <v-tab-item value="signups">
        <v-card :loading="signupLoading">
          <v-list
            subheader
            two-line
            v-for="(signupGroup, index) in signupGroups"
            :key="index"
          >
            <v-subheader class="overline"
              >{{ signupGroup.signups.length }}
              {{ signupGroup.status }}</v-subheader
            >
            <v-list-item v-for="signup in signupGroup.signups" :key="signup.id">
              <v-list-item-avatar color="grey">
                <v-gravatar :email="signup.user.email" v-if="!signup.guest" />
                <v-avatar v-else size="40" color="grey">
                  <v-icon>mdi-account-plus</v-icon></v-avatar
                >
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-if="signup.guest_name">
                  {{ signup.guest_name }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  {{ signup.display_name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="!signup.guest && group.user_is_admin"
                >
                  {{ signup.user.email }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="signup.guest_name">
                  {{ signup.member_display_name }}'s +1
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="group.user_is_admin">
                <v-menu auto @click.native.stop>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :color="statusColors[signup.status]">{{
                      signup.status
                        ? statusIcons[signup.status]
                        : "mdi-plus-thick"
                    }}</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(value, status) in statusArgs"
                      :key="status"
                      @click="
                        sendSignup({
                          game,
                          status: value,
                          user: signup.user,
                          signup: signup
                        })
                      "
                    >
                      <v-list-item-title class="text-uppercase caption">
                        {{ status }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <!-- NOT REPLIED -->
          <v-list v-if="game.not_replied.length">
            <v-subheader class="overline"
              >{{ game.not_replied.length }} not replied</v-subheader
            >
            <v-list-item v-for="member in game.not_replied" :key="member.id">
              <v-list-item-avatar color="grey"
                ><v-gravatar :email="member.user.email"
              /></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >{{ member.display_name }}
                  <span
                    class="caption warning--text ml-1"
                    v-if="member.role.signup_days < 0"
                    >{{ member.role.title }}</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle v-if="group.user_is_admin">{{
                  member.user.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="group.user_is_admin">
                <v-menu auto @click.native.stop>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-plus-thick</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(value, status) in statusArgs"
                      :key="status"
                      @click="
                        sendSignup({ game, status: value, user: member.user })
                      "
                    >
                      <v-list-item-title class="text-uppercase caption">
                        {{ status }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      v-if="group.user_is_admin"
      v-model="fab"
      bottom
      right
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="green"
            v-on="on"
            :to="{ name: 'GameEdit', params: { game_id, group_id } }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{
          game.parent ? "Edit This Game Only" : "Edit This Game"
        }}</span>
      </v-tooltip>
      <v-tooltip left v-if="game.parent && !game.exception">
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            color="red"
            v-on="on"
            :to="{
              name: 'GameEdit',
              params: { game_id: game.parent, group_id },
              query: { instance: game_id }
            }"
          >
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Edit Series</span>
      </v-tooltip>
    </v-speed-dial>
  </OneColumnPage>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  statusArgs,
  statusColors,
  statusIcons
} from "@/store/modules/games/variables";
import Comments from "@/components/Comments";

export default {
  name: "Game",
  components: {
    Comments
  },
  data: () => ({
    fab: false,
    statusArgs,
    statusColors,
    statusIcons,
    isMobile: false
  }),
  props: ["game_id", "group_id"],
  methods: {
    ...mapActions("games", ["fetchGameDetail", "sendSignup", "setGuestName"])
  },
  computed: {
    ...mapState("games", ["game", "requestLoading", "signupLoading"]),
    ...mapState("comments", ["comments"]),
    ...mapState("groups", ["group"]),
    ...mapGetters("games", [
      "signupGroups",
      "signedUpCount",
      "waitlistedCount",
      "userSignups",
      "userIsSignedUp"
    ])
  },
  created() {
    this.isMobile = this.$vuetify.breakpoint.name === "xs";
    this.fetchGameDetail(this.game_id).then(() => {
      this.game = this.$store.state.games.game;
      let query = Object.assign({}, this.$route.query);
      if (query.status) {
        if (this.$route.query.status) {
          this.sendSignup({
            game: this.game,
            status: this.$route.query.status
          });
        }
        delete query.status;
        this.$router.replace({ query });
      }
    });
  }
};
</script>
