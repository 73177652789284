<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <div v-if="successMessage">
          <v-alert type="success">
            {{ successMessage }}
          </v-alert>
          <v-btn color="primary" to="/login">Login</v-btn>
        </div>
        <div v-else>
          <v-card class="elevation-12 pb-2">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Set Password</v-toolbar-title>
            </v-toolbar>
            <!-- <v-card-subtitle>
              Already have an account?
              <
            </v-card-subtitle> -->
            <v-card-text>
              <v-form
                @submit.prevent="submit"
                @keyup.native.enter="resetPassword(inputs)"
              >
                <v-text-field
                  v-model="inputs.password1"
                  id="password1"
                  label="Password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :error="formErrors.new_password1"
                  :error-messages="formErrors.new_password1"
                />
                <v-text-field
                  v-model="inputs.password2"
                  id="password2"
                  label="Repeat Password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :error="formErrors && formErrors.new_password2"
                  :error-messages="formErrors.new_password2"
                />
              </v-form>
              <v-alert
                outlined
                type="error"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                {{ error }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :loading="resetLoading"
                color="primary"
                @click="resetPassword(inputs)"
                >Set Password</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      inputs: {
        password1: "",
        password2: "",
        uid: this.$route.params.uid,
        token: this.$route.params.token
      }
    };
  },
  computed: {
    ...mapState({
      formErrors: state =>
        state.password.resetError ? state.password.resetError.response.data : {}
    }),
    ...mapState("password", [
      "resetCompleted",
      "resetError",
      "resetLoading",
      "successMessage"
    ])
  },
  methods: {
    ...mapActions("password", ["resetPassword", "clearResetStatus"]),
    beforeRouteLeave(to, from, next) {
      this.clearResetStatus();
      next();
    }
  }
};
</script>

<style>
form input {
  display: block;
}
</style>
