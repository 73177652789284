import notificationsAPI from "@/api/notifications";

export default {
  data() {
    return {
      showNotificationSnackbar: false
    };
  },
  methods: {
    subscribeUser() {
      console.log("Attempting to subscribe user...");
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          if (registration) {
            console.log(
              "Service Worker state:",
              registration.active ? "Active" : "Inactive"
            );
            if (registration.active.state === "activated") {
              console.log("Service Worker is activated and running.");
            } else {
              console.log("Service Worker state:", registration.active.state);
            }
          } else {
            console.log("No service worker registered for this origin.");
          }
        });
      }
      if ("serviceWorker" in navigator && "PushManager" in window) {
        console.log("Service Worker and PushManager are available");
        navigator.serviceWorker.ready
          .then((registration) => {
            console.log("Service Worker is ready:", registration);
            console.log(
              "VAPID Key:",
              this.urlBase64ToUint8Array(process.env.VUE_APP_PUBLIC_VAPID_KEY)
            );
            this.getDeviceUID().then((deviceUID) => {
              if (deviceUID) {
                registration.pushManager
                  .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array(
                      process.env.VUE_APP_PUBLIC_VAPID_KEY
                    )
                  })
                  .then((subscription) => {
                    // Handle successful subscription, e.g., send subscription to backend
                    console.log("Subscription successful:", subscription);
                    this.handleSubscription(subscription, deviceUID);
                  })
                  .catch((error) => {
                    console.error("Error during subscription:", error);
                  });
              } else {
                console.error("Device UID not found.");
                // Handle the error appropriately
              }
            });
          })
          .catch((error) => {
            console.error("Service Worker registration failed:", error);
          });
      } else {
        console.log("Service Worker or PushManager not available");
      }
    },
    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    handleSubscription(subscription, deviceUID) {
      const deviceInfo = this.getDeviceInfo();

      const subscriptionWithDetails = {
        subscription: subscription,
        deviceInfo: deviceInfo,
        deviceUID: deviceUID
      };

      notificationsAPI
        .subscribe(subscriptionWithDetails)
        .then((response) =>
          console.log("Subscription with details sent to backend:", response)
        )
        .catch((error) =>
          console.error(
            "Error sending subscription with details to backend:",
            error
          )
        );
    },
    getDeviceInfo() {
      const userAgent = navigator.userAgent;
      let browserName, browserVersion, osName;

      // Extract browser name and version
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
        browserVersion = userAgent.match(/chrome\/([\d.]+)/i)[1];
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
        browserVersion = userAgent.match(/firefox\/([\d.]+)/i)[1];
      } else if (userAgent.match(/safari/i)) {
        browserName = "Safari";
        browserVersion = userAgent.match(/version\/([\d.]+)/i)[1];
      } else if (userAgent.match(/edg/i)) {
        browserName = "Edge";
        browserVersion = userAgent.match(/edg\/([\d.]+)/i)[1];
      } else {
        browserName = "Unknown";
        browserVersion = "Unknown";
      }

      // Extract OS name
      if (userAgent.match(/android/i)) {
        osName = "Android";
      } else if (userAgent.match(/ios|iphone|ipad|ipod/i)) {
        osName = "iOS";
      } else if (userAgent.match(/macintosh|mac os x/i)) {
        osName = "MacOS";
      } else if (userAgent.match(/windows|win32/i)) {
        osName = "Windows";
      } else if (userAgent.match(/linux/i)) {
        osName = "Linux";
      } else {
        osName = "Unknown";
      }

      return {
        browserName,
        browserVersion,
        osName
      };
    },
    async getDeviceUID() {
      const cache = await caches.open("my-app-cache");
      const uidResponse = await cache.match("device-uid");
      if (uidResponse) {
        const uid = await uidResponse.text();
        return uid;
      }
      // Handle the case where UID might not be available
      return null;
    },
    requestNotificationPermission() {
      // Show snackbar to ask user if they want to enable notifications
      this.showNotificationSnackbar = true;
    }
  },
  created() {
    // this.subscribeUser();
  }
};
