<template>
  <OneColumnPage>
    <template v-if="error && error.response.status === 404">
      <v-alert type="error">
        This doesn't seem to be one of your groups.
      </v-alert>
      <v-btn to="/">Take Me Home</v-btn>
    </template>
    <template v-else>
      <v-card :loading="requestLoading">
        <v-skeleton-loader
          type="card-heading, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
          class="mx-auto"
          :loading="requestLoading"
        >
          <v-card-title>{{ group.title }}</v-card-title>
          <v-card-text>{{ group.description }}</v-card-text>
        </v-skeleton-loader>
      </v-card>

      <v-card class="my-2">
        <v-skeleton-loader
          type="list-item, list-item-avatar-two-line"
          class="mx-auto"
          :loading="requestLoading"
        >
          <v-list subheader two-line>
            <v-subheader class="overline">Upcoming Games</v-subheader>
            <v-list-item
              v-for="game in group.upcoming_games"
              :key="game.id"
              @click="
                $router.push({
                  name: 'GameDetail',
                  params: { game_id: game.id, group_id }
                })
              "
            >
              <v-list-item-avatar>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ game.date | moment("ddd, MMMM D") }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ [game.start, "HH:mm:ss"] | moment("h:mm a") }}
                  -
                  {{ [game.end, "HH:mm:ss"] | moment("h:mm a") }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="game.user_status">
                <v-icon :color="statusColors[game.user_status]">{{
                  statusIcons[game.user_status]
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-action v-else>
                <v-menu auto @click.native.stop>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-plus-thick</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(value, status) in statusArgs"
                      :key="status"
                      @click="sendSignup({ game, status: value })"
                    >
                      <v-list-item-title class="text-uppercase caption">
                        {{ status }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-skeleton-loader>
      </v-card>

      <v-tabs
        background-color="secondary"
        class="elevation-2"
        centered
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#comments">
          Comments
          <v-icon>mdi-comment-text</v-icon>
        </v-tab>
        <v-tab href="#members">
          Members
          <v-icon>mdi-account-multiple</v-icon>
        </v-tab>

        <v-tab-item value="comments">
          <Comments
            v-if="group.id === parseInt(group_id)"
            object_type="group"
            :object_pk="group_id"
          />
        </v-tab-item>

        <v-tab-item value="members">
          <v-card class="mt-1">
            <v-skeleton-loader
              type="list-item"
              class="mx-auto"
              :loading="requestLoading"
            >
              <v-list subheader>
                <v-subheader class="overline"
                  >{{
                    !!group.members && group.members.length
                  }}
                  Members</v-subheader
                >
                <v-list-item v-for="member in group.members" :key="member.id">
                  <v-list-item-avatar color="grey"
                    ><v-gravatar :email="member.user.email"
                  /></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ member.user.first_name }}
                      {{ member.user.last_name }}</v-list-item-title
                    >
                    <v-list-item-subtitle v-if="group.user_is_admin">{{
                      member.user.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-menu auto v-if="group.user_is_admin">
                      <template v-slot:activator="{ on }">
                        <v-btn text small v-on="on">
                          {{ member.role.title }} <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(role, i) in roles"
                          :key="i"
                          :input-value="member.role.id === role.id"
                          @click="updateMemberRole({ member, role })"
                        >
                          <v-list-item-title class="text-uppercase caption">
                            {{ role.title }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="askDeleteMember(member)">
                          <v-list-item-title class="text-uppercase caption">
                            Remove
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu auto v-else-if="member.user.id === user.pk">
                      <template v-slot:activator="{ on }">
                        <v-btn text small v-on="on">
                          <v-icon>mdi-exit-run</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item @click="askDeleteMember(member)">
                          <v-list-item-title class="text-uppercase caption">
                            Leave Group
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-skeleton-loader>
          </v-card>
        </v-tab-item>
      </v-tabs>

      <v-dialog v-model="dialogDelete" max-width="290" v-if="member_to_delete">
        <v-card>
          <v-card-title class="headline">
            Remove {{ member_to_delete.user.first_name }}?
          </v-card-title>

          <v-card-text>
            Remove {{ member_to_delete.user.first_name }}
            {{ member_to_delete.user.last_name }} from {{ group.title }}?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogDeleteHide">
              No
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirmDeleteMember(member_to_delete)"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-speed-dial
        v-if="group.user_is_admin"
        v-model="fab"
        bottom
        right
        fixed
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="primary" fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="red"
              v-on="on"
              :to="{ name: 'NewGame', params: { group_id } }"
            >
              <v-icon>mdi-calendar-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Games</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="indigo"
              v-on="on"
              :to="{ name: 'GroupInviteForm', params: { group_id } }"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Members</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="green"
              v-on="on"
              :to="{ name: 'GroupEdit', params: { group_id } }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Group</span>
        </v-tooltip>
      </v-speed-dial>
    </template>
  </OneColumnPage>
</template>

<script>
import {
  statusArgs,
  statusColors,
  statusIcons
} from "@/store/modules/games/variables";
import { get, sync, call } from "vuex-pathify";

export default {
  name: "Group",
  data: () => ({
    dialogDelete: false,
    member_to_delete: null,
    fab: false,
    statusArgs,
    statusColors,
    statusIcons
  }),
  props: ["group_id"],
  methods: {
    ...call("groups", [
      "fetchGroupDetail",
      "fetchRoles",
      "updateMemberRole",
      "deleteMember"
    ]),

    ...call("games", ["sendSignup"]),

    askDeleteMember(member) {
      this.member_to_delete = member;
      this.dialogDelete = true;
    },

    confirmDeleteMember(member) {
      this.deleteMember(member).then(this.dialogDeleteHide);
    },

    dialogDeleteHide() {
      this.member_to_delete = null;
      this.dialogDelete = false;
    }
  },
  computed: {
    ...sync("groups", ["group", "requestLoading", "roles", "error"]),
    user: get("auth/user")
  },
  created() {
    this.fetchGroupDetail(this.group_id);
    this.fetchRoles();
  }
};
</script>
