<template>
  <div>
    <v-item-group
      v-model="internalValue"
      class="mx-auto row row--dense"
      mandatory
    >
      <v-col v-for="{ icon, text, value } in items" :key="text" cols="12">
        <v-item :value="value">
          <template #default="{ active, toggle }">
            <v-card
              :color="
                active ? 'primary' : `grey ${dark ? 'darken' : 'lighten'}-3`
              "
              :dark="!dark && active"
              class="v-card--group py-3 px-4 text-center position-relative cursor-pointer d-flex align-center justify-space-between"
              rounded
              flat
              @click="toggle"
              :value="value"
            >
              {{ text }}

              <v-icon> {{ icon }} </v-icon>
            </v-card>
          </template>
        </v-item>
      </v-col>
    </v-item-group>
  </div>
</template>

<script>
// Mixins
import Proxyable from "vuetify/lib/mixins/proxyable";
// Utilities
export default {
  name: "SettingsGroup",
  mixins: [Proxyable],
  data: () => ({ path: "" }),
  computed: {
    items: () => []
  }
};
</script>

<style lang="sass">
// Bug in Vuetify, ripple isnt inheriting border-radius
.v-card--group::before
  border-radius: inherit
</style>
