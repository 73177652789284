import session from "./session";

export default {
  subscribe(subscription) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/notifications/subscribe/`,
      subscription
    );
  },
  fetchSubscriptions() {
    return session.get(
      `${process.env.VUE_APP_API_URL}/notifications/subscriptions/`
    );
  },
  updateSubscriptionStatus(subscriptionId, newStatus) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/notifications/subscriptions/update_status/`,
      { subscription_id: subscriptionId, new_status: newStatus }
    );
  },
  toggleNotificationOptIn(subscriptionId, type, optIn) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/notifications/subscriptions/toggle_opt_in/`,
      { subscription_id: subscriptionId, type: type, opt_in: optIn }
    );
  }
};
