<template>
  <OneColumnPage>
    <v-alert type="primary">
      <div class="title">Welcome to PickupGoat</div>
      This is a prototype (and a work-in-progress). Expect some bugs.<br />
      <a
        href="mailto:felix@pickupgoat.com"
        target="_blank"
        class="secondary--text text--lighten-5"
        >Please email Felix</a
      >
      if you encounter any issues or have any suggestions.
    </v-alert>
    <v-list
      subheader
      v-if="groups.length > 0 || requestLoading"
      :loading="requestLoading"
    >
      <v-subheader>Your Groups</v-subheader>

      <v-list-item
        v-for="group in groups"
        :key="group.id"
        class="group"
        :to="'/groups/' + group.id"
      >
        <v-list-item-content>
          <v-list-item-title>{{ group.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon color="primary">mdi-account-group</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-alert type="primary" v-else>
      <div class="title">You are not in any groups yet</div>
      <ul>
        <li>ask an admin of an existing group to send you an invite, or</li>
        <li>click the button at the bottom right to create a new group</li>
      </ul>
    </v-alert>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn fab dark small color="green" v-on="on" to="/groups/new">
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-btn>
        </template>
        <span>Create Group</span>
      </v-tooltip>
    </v-speed-dial>
    <v-snackbar v-model="showNotificationSnackbar" timeout="0">
      Do you want to receive push notifications about games and comments?
      <v-btn color="green" text @click="handleNotificationsConfirm">Yes</v-btn>
      <v-btn color="red" text @click="handleNotificationsDecline">No</v-btn>
    </v-snackbar>
  </OneColumnPage>
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import subscribeMixin from "@/mixins/notifications/subscribe";

export default {
  mixins: [subscribeMixin],
  data() {
    return {
      fab: false
    };
  },
  name: "Groups",
  methods: {
    fetchGroups: call("groups/fetchGroups"),
    handleNotificationsConfirm() {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          this.subscribeUser().then(() => {
            localStorage.setItem("notificationsAccepted", "true");
          });
        }
        this.showNotificationSnackbar = false;
      });
    },
    handleNotificationsDecline() {
      localStorage.setItem("notificationsDeclined", "true");
      this.showNotificationSnackbar = false;
    }
  },
  computed: {
    ...sync("groups", ["groups", "error", "requestLoading"]),
    user: get("auth/user")
  },
  created() {
    this.fetchGroups();
    if (
      !localStorage.getItem("notificationsDeclined") &&
      !localStorage.getItem("notificationsAccepted") &&
      Notification.permission !== "granted"
    ) {
      this.requestNotificationPermission();
    }
  }
};
</script>
