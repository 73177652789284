import session from "./session";

export default {
  login(email, password) {
    return session.post(`${process.env.VUE_APP_API_URL}/auth/login/`, {
      email,
      password
    });
  },
  logout() {
    return session.get(`${process.env.VUE_APP_API_URL}/auth/logout/`);
  },
  createAccount(first_name, last_name, email, password1, password2) {
    return session.post(`${process.env.VUE_APP_API_URL}/registration/`, {
      first_name,
      last_name,
      email,
      password1,
      password2,
      invite_code: sessionStorage.invite_code
    });
  },
  changeAccountPassword(password1, password2) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/auth/password/change/`,
      { password1, password2 }
    );
  },
  sendAccountPasswordResetEmail(email) {
    return session.post(`${process.env.VUE_APP_API_URL}/auth/password/reset/`, {
      email
    });
  },
  resetAccountPassword(uid, token, new_password1, new_password2) {
    // eslint-disable-line camelcase
    return session.post(
      `${process.env.VUE_APP_API_URL}/auth/password/reset/confirm/`,
      {
        uid,
        token,
        new_password1,
        new_password2
      }
    );
  },
  getAccountDetails() {
    return session.get(`${process.env.VUE_APP_API_URL}/auth/user/`);
  },
  updateAccountDetails(first_name, last_name, email) {
    return session.patch(`${process.env.VUE_APP_API_URL}/auth/user/`, {
      first_name,
      last_name,
      email
    });
  },
  verifyAccountEmail(key) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/registrations/verify-email/`,
      { key }
    );
  }
};
