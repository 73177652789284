import notificationsAPI from "@/api/notifications";

const state = {
  subscriptions: [],
  deviceUID: null
};

const actions = {
  fetchSubscriptions({ commit }) {
    notificationsAPI
      .fetchSubscriptions()
      .then((response) => {
        commit("SET_SUBSCRIPTIONS", response.data);
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error);
      });
  },
  async getDeviceUID({ commit }) {
    const cache = await caches.open("my-app-cache");
    const uidResponse = await cache.match("device-uid");
    if (uidResponse) {
      const uid = await uidResponse.text();
      commit("SET_DEVICE_UID", uid);
    } else {
      console.error("Device UID not found.");
      commit("SET_DEVICE_UID", "uid-not-found");
    }
  },
  updateSubscriptionStatus({ commit }, { subscriptionId, newStatus }) {
    console.log("Updating subscription status:", subscriptionId, newStatus);
    return notificationsAPI
      .updateSubscriptionStatus(subscriptionId, newStatus)
      .then((response) => {
        if (response.data.success) {
          commit("UPDATE_SUBSCRIPTION_STATUS", { subscriptionId, newStatus });
        }
      })
      .catch((error) => {
        console.error("Error updating notification state:", error);
      });
  },
  toggleNotificationOptIn({ commit }, { subscriptionId, type, optIn }) {
    console.log("Toggling notification opt-in:", subscriptionId, type, optIn);
    return notificationsAPI
      .toggleNotificationOptIn(subscriptionId, type, optIn)
      .then((response) => {
        if (response.data.success) {
          commit("TOGGLE_NOTIFICATION_OPT_IN", { subscriptionId, type, optIn });
        }
      })
      .catch((error) => {
        console.error("Error toggling notification opt-in:", error);
      });
  }
};

const mutations = {
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  SET_DEVICE_UID(state, uid) {
    state.deviceUID = uid;
  },
  UPDATE_SUBSCRIPTION_STATUS(state, { subscriptionId, newStatus }) {
    const index = state.subscriptions.findIndex(
      (subscription) => subscription.id === subscriptionId
    );
    if (index !== -1) {
      state.subscriptions[index].status = newStatus;
    }
  },
  TOGGLE_NOTIFICATION_OPT_IN(state, { subscriptionId, type, optIn }) {
    const subscriptionIndex = state.subscriptions.findIndex(
      (subscription) => subscription.id === subscriptionId
    );
    if (subscriptionIndex !== -1) {
      const subscription = state.subscriptions[subscriptionIndex];
      subscription.notification_types[type] = optIn;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
