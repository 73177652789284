<template>
  <v-card class="elevation-12 my-2 pb-2">
    <v-toolbar color="primary" flat>
      <v-toolbar-title>
        Push Notifications
        <!-- <v-btn icon @click="fetchSubscriptions">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn> -->
      </v-toolbar-title>
    </v-toolbar>
    <v-alert
      v-if="notificationPermission === 'denied'"
      type="error"
      class="mb-4"
    >
      Notifications are blocked in your browser. Please enable them in your
      browser settings to use this feature.
    </v-alert>
    <!-- New alert for when notification permission is not set -->
    <v-alert
      v-if="notificationPermission === 'default'"
      type="warning"
      class="mb-4"
    >
      Notifications are not enabled.
      <v-btn color="primary" @click="requestNotificationPermission">
        Enable Notifications
      </v-btn>
    </v-alert>
    <v-list>
      <template v-for="subscription in subscriptions">
        <v-list-item :key="subscription.id" class="pt-2">
          <v-list-item-content>
            <v-list-item-title>
              {{ subscription.device_info.browserName }} on
              {{ subscription.device_info.osName }}
              <v-chip
                x-small
                class="ml-2"
                color="primary"
                dark
                v-if="subscription.device_uid === deviceUID"
              >
                This Device
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              :color="subscription.status === 'active' ? 'success' : 'grey'"
              dark
              @click.stop="toggleSubscriptionStatus(subscription.id)"
            >
              {{ subscription.status === "active" ? "On" : "Off" }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <!-- List of notification types for active subscriptions immediately under their corresponding subscription -->
        <v-list-item
          v-if="subscription.status === 'active'"
          v-for="(optIn, type) in subscription.notification_types"
          :key="subscription.id + '-' + type"
        >
          <v-list-item-action>
            <v-checkbox
              :input-value="optIn"
              :color="optIn ? 'success' : 'grey'"
              @click.stop="
                toggleNotificationOptIn({
                  subscriptionId: subscription.id,
                  type,
                  optIn: !optIn
                })
              "
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-title>{{ type }}</v-list-item-title>
        </v-list-item>
        <v-divider
          v-if="subscription.id !== subscriptions[subscriptions.length - 1].id"
          :key="'divider-' + subscription.id"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import subscribeMixin from "@/mixins/notifications/subscribe";

export default {
  mixins: [subscribeMixin],
  data() {
    return {
      notificationPermission: Notification.permission
    };
  },
  created() {
    this.fetchSubscriptions();
    this.getDeviceUID();
  },
  computed: {
    ...mapState("notifications", ["subscriptions", "deviceUID"])
  },
  methods: {
    ...mapActions("notifications", [
      "fetchSubscriptions",
      "getDeviceUID",
      "updateSubscriptionStatus",
      "toggleNotificationOptIn"
    ]),
    toggleSubscriptionStatus(subscriptionId) {
      this.updateSubscriptionStatus({
        subscriptionId,
        newStatus:
          this.subscriptions.find((sub) => sub.id === subscriptionId).status ===
          "active"
            ? "paused"
            : "active"
      });
    },
    requestNotificationPermission() {
      Notification.requestPermission().then((permission) => {
        this.notificationPermission = permission;
        if (permission === "granted") {
          this.subscribeUser();
        }
      });
    }
  }
};
</script>
