<template>
  <OneColumnPage>
    <v-card class="elevation-12 pb-2">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>{{
          inputs.id ? "Edit Group" : "Create A New Group"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form
          @submit.prevent="submit"
          @keyup.native.enter.exact="saveGroup(inputs)"
        >
          <v-text-field
            v-model="inputs.title"
            id="title"
            label="Title"
            prepend-icon="mdi-label"
            type="text"
            :error="formErrors.title"
            :error-messages="formErrors.title"
          />
          <v-textarea
            v-model="inputs.description"
            id="description"
            label="Description"
            prepend-icon="mdi-card-text-outline"
            type="textarea"
            :error="formErrors.description"
            :error-messages="formErrors.description"
          />
        </v-form>
        <v-alert
          outlined
          type="error"
          v-for="(error, i) in formErrors.non_field_errors"
          :key="i"
        >
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="requestLoading"
          color="primary"
          @click="saveGroup(inputs)"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </OneColumnPage>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "GroupForm",
  props: ["group_id"],
  computed: {
    ...mapState({
      inputs: state => state.groups.group,
      formErrors: state =>
        state.groups.error ? state.groups.error.response.data : {}
    }),
    ...mapState("groups", ["error", "requestLoading"])
  },
  methods: {
    ...mapActions("groups", ["saveGroup", "fetchGroupDetail"])
  },
  created() {
    if (this.group_id) this.fetchGroupDetail(this.group_id);
  }
};
</script>
