import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import EditAccount from "../views/auth/EditAccount.vue";
import VerifyEmail from "../views/auth/VerifyEmail.vue";
import PasswordReset from "../views/auth/PasswordReset.vue";
import PasswordResetConfirm from "../views/auth/PasswordResetConfirm.vue";
import Lost from "../views/Lost";
import Groups from "../views/groups/Groups";
import GroupForm from "../views/groups/GroupForm";
import GroupDetail from "../views/groups/GroupDetail";
import GroupInviteForm from "../views/groups/GroupInviteForm";
import GameForm from "../views/games/GameForm";
import GameDetail from "../views/games/GameDetail";

import store from "../store";

const requireAuthenticated = (to, from, next) => {
  if (!store.getters["auth/initialized"]) {
    store
      .dispatch("auth/initialize")
      .then(() => {
        if (!store.getters["auth/isAuthenticated"]) {
          const og_to = to;
          next({ name: "Login", query: { ...og_to.query, from: og_to.path } });
        } else next();
      })
      .catch((error) => {
        console.error("Error during authentication initialization:", error);
        next(error);
      });
  } else {
    if (!store.getters["auth/isAuthenticated"]) next("/login");
    else next();
  }
};

const requireUnauthenticated = (to, from, next) => {
  if (!store.getters["auth/initialized"]) {
    store
      .dispatch("auth/initialize")
      .then(() => {
        if (store.getters["auth/isAuthenticated"]) next("/groups");
        else next();
      })
      .catch((error) => {
        console.error("Error during authentication initialization:", error);
        next(error);
      });
  } else {
    if (store.getters["auth/isAuthenticated"]) next("/groups");
    else next();
  }
};

const redirectLogout = (to, from, next) => {
  store
    .dispatch("auth/logout")
    .then(() => next("/login"))
    .catch((error) => {
      console.error("Error during logout:", error);
      next(error);
    });
};

Vue.use(VueRouter);

// look at route level code-splitting if this app gets bigger

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: requireUnauthenticated
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
    beforeEnter: requireAuthenticated
  },
  {
    path: "/groups/new",
    component: GroupForm,
    beforeEnter: requireAuthenticated
  },
  {
    path: "/groups/:group_id",
    props: true,
    name: "GroupDetail",
    component: GroupDetail,
    beforeEnter: requireAuthenticated
  },
  {
    path: "/groups/:group_id/edit",
    props: true,
    name: "GroupEdit",
    component: GroupForm,
    beforeEnter: requireAuthenticated
  },
  {
    path: "/groups/:group_id/invite",
    props: true,
    name: "GroupInviteForm",
    component: GroupInviteForm,
    beforeEnter: requireAuthenticated
  },
  {
    path: "/groups/:group_id/games/new",
    props: true,
    name: "NewGame",
    component: GameForm,
    beforeEnter: (to, from, next) => {
      store.dispatch("games/resetGame");
      requireAuthenticated(to, from, next);
    }
  },
  {
    path: "/groups/:group_id/games/:game_id/edit",
    props: true,
    name: "GameEdit",
    component: GameForm,
    beforeEnter: requireAuthenticated
  },
  {
    path: "/groups/:group_id/games/:game_id",
    props: true,
    name: "GameDetail",
    component: GameDetail,
    beforeEnter: requireAuthenticated
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: requireUnauthenticated
  },
  {
    path: "/register",
    name: "Register",
    component: Register
    // beforeEnter: requireUnauthenticated
  },
  {
    path: "/account",
    name: "Account",
    component: EditAccount
    // beforeEnter: requireUnauthenticated
  },
  {
    path: "/register/confirm-email/:key",
    component: VerifyEmail
  },
  {
    path: "/password_reset",
    component: PasswordReset
  },
  {
    path: "/password/reset/:uid/:token",
    component: PasswordResetConfirm
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: redirectLogout
  },
  {
    path: "*",
    component: Lost
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
