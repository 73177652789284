<template>
  <v-row justify="center" class="mb-5 pb-5">
    <v-col cols="12" md="9" lg="8" class="mb-5">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "OneColumnPage",

  data: () => ({})
};
</script>
