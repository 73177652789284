<template>
  <OneColumnPage>
    <v-alert type="primary">
      <div class="title">Welcome to PickupGoat</div>
      This is a prototype. Expect some bugs.<br />
      <a
        href="mailto:felix@pickupgoat.com"
        target="_blank"
        class="secondary--text text--lighten-5"
        >Let Felix know</a
      >
      if you encounter any issues or have any suggestions.
    </v-alert>
    <p v-if="isAuthenticated">
      To start, go to
      <router-link class="primary--text text--lighten-2" to="groups"
        >Groups</router-link
      >.
    </p>
    <p v-else>
      To start,
      <router-link class="primary--text text--lighten-2" to="register"
        >create an account</router-link
      >
      or
      <router-link class="primary--text text--lighten-2" to="login"
        >log in</router-link
      >.
    </p>
  </OneColumnPage>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Home",
  components: {},

  computed: {
    ...mapGetters({ isAuthenticated: "auth/isAuthenticated" })
  }
};
</script>
