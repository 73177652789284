export const AUTH_INITIALIZED = "AUTH_INITIALIZED";
export const AUTH_BEGIN = "AUTH_BEGIN";
export const AUTH_CLEAR = "AUTH_CLEAR";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_CLEAR = "LOGIN_CLEAR";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const PASSWORD_EMAIL_BEGIN = "PASSWORD_EMAIL_BEGIN";
export const PASSWORD_EMAIL_CLEAR = "PASSWORD_EMAIL_CLEAR";
export const PASSWORD_EMAIL_FAILURE = "PASSWORD_EMAIL_FAILURE";
export const PASSWORD_EMAIL_SUCCESS = "PASSWORD_EMAIL_SUCCESS";
export const PASSWORD_RESET_BEGIN = "PASSWORD_RESET_BEGIN";
export const PASSWORD_RESET_CLEAR = "PASSWORD_RESET_CLEAR";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const SET_CSRFTOKEN = "SET_CSRFTOKEN";
export const REMOVE_CSRFTOKEN = "REMOVE_CSRFTOKEN";
export const SET_USER = "SET_USER";
