<template>
  <OneColumnPage>
    <v-alert
      type="warning"
      v-if="
        inputs.id && (inputs.parent || inputs.recurrences) && !inputs.exception
      "
    >
      {{
        inputs.parent
          ? "This game will be decoupled from its parent series upon saving (if you made any changes)."
          : "Changes will be applied to all future games in this series."
      }}
    </v-alert>
    <v-card class="elevation-12 pb-2">
      <v-toolbar color="primary" flat>
        <v-toolbar-title>{{
          inputs.id ? "Edit Game" : "Create A New Game"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form
          @submit.prevent="submit"
          @keyup.native.enter.exact="saveGame(inputs)"
        >
          <!-- TITLE -->
          <v-text-field
            v-model="inputs.title"
            id="title"
            label="Title"
            prepend-icon="mdi-label"
            type="text"
            :error="formErrors.title"
            :error-messages="formErrors.title"
          />
          <!-- DESCRIPTION -->
          <v-textarea
            v-model="inputs.description"
            id="description"
            label="Description"
            prepend-icon="mdi-card-text-outline"
            type="textarea"
            :error="formErrors.description"
            :error-messages="formErrors.description"
          />
          <!-- MAX_PLAYERS -->
          <v-text-field
            v-model="inputs.max_players"
            id="max_players"
            label="Max Players"
            prepend-icon="mdi-account-multiple-check"
            type="number"
            :error="formErrors.max_players"
            :error-messages="formErrors.max_players"
          />
          <!-- DATE -->
          <v-dialog
            ref="dialogDate"
            v-model="modalDate"
            :return-value.sync="inputs.date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="inputs.date"
                label="Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                @blur="date = parseDate(inputs.date)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="inputs.date"
              color="primary"
              reactive
              show-current
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalDate = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.dialogDate.save(inputs.date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
          <!-- START -->
          <v-dialog
            ref="dialogStart"
            v-model="modalStart"
            :return-value.sync="inputs.start"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="inputs.start"
                label="Start"
                prepend-icon="mdi-clock-outline"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modalStart"
              v-model="inputs.start"
              full-width
              ampm-in-title="true"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalStart = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.dialogStart.save(inputs.start)"
                >OK</v-btn
              >
            </v-time-picker>
          </v-dialog>
          <v-dialog
            ref="dialogEnd"
            v-model="modalEnd"
            :return-value.sync="inputs.end"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="inputs.end"
                label="End"
                prepend-icon=" "
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="modalEnd"
              v-model="inputs.end"
              full-width
              ampm-in-title="true"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalEnd = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.dialogEnd.save(inputs.end)"
                >OK</v-btn
              >
            </v-time-picker>
          </v-dialog>
          <v-checkbox
            v-model="inputs.recurrences"
            label="Repeat Weekly"
            value="RRULE:FREQ=WEEKLY"
          ></v-checkbox>
        </v-form>

        <v-alert
          outlined
          type="error"
          v-for="(error, i) in formErrors.non_field_errors"
          :key="i"
        >
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="requestLoading"
          color="primary"
          @click="saveGame(inputs)"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </OneColumnPage>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "GameForm",
  data() {
    return {
      modalDate: false,
      modalStart: false,
      modalEnd: false
      //   recurrenceOptions: [
      //       { display: 'Weekly', value: "RRULE:FREQ=WEEKLY" },
      //       { display: 'Every 2 weeks', value: "RRULE:FREQ=WEEKLY;INTERVAL=2" },
      //   ]
    };
  },
  props: ["group_id", "game_id"],
  computed: {
    ...mapState({
      inputs: state => state.games.game,
      formErrors: state =>
        state.games.error ? state.games.error.response.data : {}
    }),
    ...mapState("games", ["error", "requestLoading"])
  },
  methods: {
    ...mapActions("games", ["saveGame", "fetchGameDetail"])
  },
  created() {
    if (this.game_id) this.fetchGameDetail(this.game_id);
  },
  mounted() {
    this.inputs.group = this.group_id;
  }
};
</script>
