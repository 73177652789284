import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import groups from "./modules/groups/groups";
import games from "./modules/games/games";
import comments from "./modules/comments/comments";
import auth from "./modules/auth/auth";
import signup from "./modules/auth/signup";
import password from "./modules/auth/password";
import user from "./modules/auth/user";
import notifications from "./modules/notifications/notifications";

import pathify from "vuex-pathify";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [pathify.plugin],
  modules: {
    groups,
    games,
    comments,
    auth,
    signup,
    password,
    user,
    notifications
  }
});
