<script>
import { mapActions } from "vuex";
// Extensions
import SettingsGroup from "./SettingsGroup";
// Utilities
import { sync, call } from "vuex-pathify";
// Globals
import { IN_BROWSER } from "@/util/globals";
export default {
  name: "DefaultSettingsTheme",
  extends: SettingsGroup,
  data: () => ({
    path: "theme"
  }),
  computed: {
    ...sync("user", ["theme@dark", "theme@mixed", "theme@system"]),
    items() {
      return [
        {
          text: "theme set by system",
          value: "system",
          icon: "mdi-desktop-tower-monitor",
          cb: () => this.setSystemTheme()
        },
        {
          text: "light",
          value: "light",
          icon: "mdi-white-balance-sunny",
          cb: () => this.setTheme()
        },
        {
          text: "dark",
          value: "dark",
          icon: "mdi-weather-night",
          cb: () => this.setTheme(true)
        }
      ];
    },
    internalValue: {
      get() {
        if (this.mixed) return "mixed";
        if (this.system) return "system";
        return this.dark ? "dark" : "light";
      },
      set(val) {
        const set = this.items.find(item => item.value === val);
        set.cb();
      }
    }
  },
  watch: {
    "$vuetify.theme.dark"(val) {
      if (this.dark === val) return;
      this.dark = val;
    },
    dark(val) {
      if (this.$vuetify.theme.dark === val) return;
      this.$vuetify.theme.dark = val;
    }
  },
  created() {},
  mounted() {
    this.$nextTick(function() {
      const matchMedia = this.getMatchMedia();
      if (!matchMedia) return;
      if (this.internalValue === "system") {
        this.dark = matchMedia.matches;
      }
      matchMedia.onchange = ({ matches }) => {
        if (this.system) {
          this.dark = matches;
        }
      };
    });
    this.update();
  },
  methods: {
    ...call("user", ["fetch", "update"]),
    getMatchMedia() {
      return IN_BROWSER && window.matchMedia
        ? window.matchMedia("(prefers-color-scheme: dark)")
        : false;
    },
    setTheme(dark = false, mixed = false, system = false) {
      this.dark = dark;
      this.mixed = mixed;
      this.system = system;
      this.update();
    },
    setSystemTheme() {
      const matchMedia = this.getMatchMedia();
      if (!matchMedia) return;
      this.setTheme(matchMedia.matches, this.mixed, true);
    }
  }
};
</script>
