<template>
  <v-card class="pb-1" :loading="requestLoading || loadingComments">
    <v-skeleton-loader type="list-item" class="mx-auto" :loading="loading">
      <v-card-subtitle class="overline">Comments</v-card-subtitle>
      <div class="px-4 ">
        <v-form ref="form" @submit.prevent="submit">
          <v-textarea
            v-model="inputs.comment"
            id="comment"
            label="Write a comment..."
            type="textarea"
            auto-grow
            hint="Press enter to submit, shift+enter for new line"
            rows="2"
            :error="!!formErrors.comment"
            :error-messages="formErrors.comment"
            @keydown.enter.exact.prevent
            @keyup.enter.exact="send"
          >
            <template v-slot:prepend
              ><v-avatar size="40px" color="grey" class="mr-3"
                ><v-gravatar :email="user.email"/></v-avatar
            ></template>
          </v-textarea>
          <div class="d-flex justify-space-between mb-2">
            <v-spacer></v-spacer>
            <v-slide-y-transition>
              <v-btn
                color="default"
                @click="send"
                :loading="requestLoading"
                :disabled="!!!inputs.comment"
                >Submit</v-btn
              >
            </v-slide-y-transition>
          </div>
        </v-form>
      </div>

      <transition-group name="list-animation">
        <v-hover
          v-for="comment in comments.results"
          :key="comment.id"
          class="list-animation-item"
        >
          <template v-slot:default="{ hover }">
            <div
              class="d-flex py-4 pl-4 pr-1 comment"
              :class="comment.new && 'primary'"
              :new="true"
              @mouseenter="markRead"
            >
              <div class="mr-5">
                <v-avatar size="40px" color="grey"
                  ><v-gravatar :email="comment.user.email"
                /></v-avatar>
              </div>
              <div class="flex-grow-1">
                <div class="pr-3">
                  <div class="body-1 comment-text">{{ comment.comment }}</div>
                  <div
                    class="caption font-weight-light text--secondary d-flex justify-space-between"
                  >
                    <div>
                      {{ comment.user.first_name }} {{ comment.user.last_name }}
                    </div>

                    <div>
                      {{ comment.submit_date | moment("ddd, MMM D @ h:mm a") }}
                    </div>
                  </div>
                </div>
              </div>

              <v-fade-transition>
                <v-overlay
                  v-if="hover && comment.user.id == user.pk"
                  absolute
                  color="secondary"
                >
                  <!-- <v-btn fab color="primary" class="mx-2">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn> -->
                  <v-btn
                    fab
                    color="error"
                    class="mx-2"
                    @click.stop="askDeleteComment(comment)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </div>
          </template>
        </v-hover>
      </transition-group>
      <v-card-actions v-if="comments.next || comments.previous">
        <v-spacer></v-spacer>
        <span class="caption text--secondary mr-2">
          showing {{ comments.offset + 1 }}-{{
            comments.offset + comments.results.length
          }}
          of {{ comments.count }}</span
        >
        <v-btn
          v-if="comments.previous"
          @click="fetchComments({ url: comments.previous })"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn
          v-if="comments.next"
          @click="fetchComments({ url: comments.next })"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-card-actions>
    </v-skeleton-loader>

    <v-dialog v-model="dialogDelete" max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete Comment?
        </v-card-title>

        <v-card-text>
          Delete this comment?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDeleteHide">
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDeleteComment(comment_to_delete)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "Comments",
  data() {
    return {
      loading: true,
      dialogDelete: false,
      comment_to_delete: null,
      inputs: {
        comment: "",
        object_type: this.object_type,
        object_pk: this.object_pk
      }
    };
  },
  props: ["object_type", "object_pk"],
  methods: {
    ...mapActions("comments", [
      "resetComments",
      "fetchComments",
      "saveComment",
      "deleteComment"
    ]),
    reset() {
      this.$refs.form.reset();
    },
    newline() {
      // console.log("newline");
      this.inputs.comment = `${this.inputs.comment}\n`;
    },
    send() {
      this.saveComment(this.inputs)
        .then(this.reset)
        .catch((err) => console.log(err));
    },
    updateCommentsList() {
      if (!this.comments.previous) {
        this.fetchComments({
          object_type: this.object_type,
          object_pk: this.object_pk
        }).then(() => (this.loading = false));
      }
    },
    markRead(event) {
      event.target.classList.remove("primary");
    },
    askDeleteComment(comment) {
      this.comment_to_delete = comment;
      this.dialogDelete = true;
    },
    confirmDeleteComment(comment) {
      this.deleteComment(comment).then(this.dialogDeleteHide);
    },
    dialogDeleteHide() {
      this.comment_to_delete = null;
      this.dialogDelete = false;
    }
  },
  computed: {
    ...mapState("comments", [
      "comments",
      "requestLoading",
      "loadingComments",
      "error"
    ]),
    ...mapState("auth", ["user"]),
    ...mapState({
      formErrors: (state) => {
        if (state.comments.error) {
          if (state.comments.error.response.status === 500) {
            return {
              comment: ["Error: Comment not saved. Try again."]
            };
          }
          try {
            console.log(state.comments.error.response.data);
            return state.comments.error.response.data;
          } catch (err) {
            console.log("Comment not saved.");
            return {
              comment: ["Error: Comment not saved."]
            };
          }
        } else {
          return {};
        }
      }
    })
  },
  created: function() {},
  mounted() {
    this.resetComments().then(() => {
      this.updateCommentsList();
      this.timer = setInterval(() => {
        if (document.visibilityState === "visible") {
          this.updateCommentsList();
        }
      }, 20000);
    });
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.updateCommentsList();
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    // this.resetComments();
  }
};
</script>

<style scoped>
.comment-text {
  white-space: pre-line;
}
.comment {
  position: relative;
  transition: background-color 1s ease;
}

.list-animation-item {
  transition: all 0.4s;
}
.list-animation-enter {
  opacity: 0;
  transform: translateY(-80px);
}
.list-animation-leave-to
/* .list-animation-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-600px);
}
.list-animation-leave-active {
  position: absolute;
}
</style>
