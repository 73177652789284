<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12 mb-3 pb-2">
          <v-toolbar color="primary" flat>
            <v-toolbar-title>This is you</v-toolbar-title>
          </v-toolbar>

          <v-list-item class="pt-2">
            <v-list-item-avatar color="grey"
              ><v-gravatar :email="user.email"
            /></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                >{{ user.first_name }} {{ user.last_name }}</v-list-item-title
              >
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text
            >We use Gravatar for profile pictures -
            <a
              href="https://en.gravatar.com/support/activating-your-account/"
              target="_blank"
              >manage your Gravatars</a
            >.</v-card-text
          >
        </v-card>
        <v-alert type="warning" v-if="!user.is_verified"
          >Please verify your email by clicking the link in the email we just
          sent you.</v-alert
        >
        <div v-else-if="successMessage">
          <v-alert type="success">
            {{ successMessage }}
          </v-alert>
        </div>
        <div v-else>
          <v-card class="elevation-12 pb-2 my-2">
            <v-toolbar color="primary" flat>
              <v-toolbar-title>Edit your info</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-form
                @submit.prevent="submit"
                @keyup.native.enter="editAccount(inputs)"
              >
                <v-text-field
                  v-model="inputs.first_name"
                  id="first-name"
                  label="First Name"
                  prepend-icon="mdi-account"
                  type="text"
                  :error="formErrors.first_name"
                  :error-messages="formErrors.first_name"
                />
                <v-text-field
                  v-model="inputs.last_name"
                  id="last-name"
                  label="Last Name"
                  prepend-icon="mdi-account"
                  type="text"
                  :error="formErrors.last_name"
                  :error-messages="formErrors.last_name"
                />
                <v-text-field
                  v-model="inputs.email"
                  id="email"
                  label="Email"
                  prepend-icon="mdi-email"
                  type="text"
                  :error="formErrors.email"
                  :error-messages="formErrors.email"
                />
              </v-form>
              <v-alert
                outlined
                type="error"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                {{ error }}
              </v-alert>
              You can reset your password
              <router-link to="/password_reset">here</router-link>.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :loading="loading"
                color="primary"
                @click="editAccount(inputs)"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
          <notification-management></notification-management>
          <v-btn block class="my-2" to="/logout">Logout</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NotificationManagement from "@/components/NotificationManagement";

export default {
  components: {
    NotificationManagement
  },
  created() {},
  computed: {
    ...mapState({
      formErrors: (state) => {
        if (state.signup.error) {
          try {
            return state.signup.error.response.data;
          } catch (err) {
            return {
              non_field_errors: [
                "Something ain't jellin' right now. Might need to try again later."
              ]
            };
          }
        } else {
          return {};
        }
      }
    }),
    ...mapState("signup", ["error", "loading", "successMessage"]),
    ...mapState("auth", ["user"]),
    ...mapState({
      inputs: (state) => ({
        ...state.auth.user
      })
    })
  },
  methods: mapActions("signup", ["editAccount", "clearRegistrationStatus"]),
  beforeRouteLeave(to, from, next) {
    this.clearRegistrationStatus();
    next();
  }
};
</script>
