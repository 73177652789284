<template>
  <OneColumnPage>
    <v-card class="elevation-12 pb-2">
      <v-toolbar color="primary" flat>
        <v-toolbar-title> Invite to join {{ group.title }} </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form
          @submit.prevent="submit"
          @keyup.native.enter.exact="saveGroupInvite(inputs)"
        >
          <v-text-field
            v-model="inputs.name"
            id="name"
            label="Name"
            prepend-icon="mdi-account"
            type="text"
            :error="formErrors.name"
            :error-messages="formErrors.name"
          />
          <v-text-field
            v-model="inputs.email"
            id="email"
            label="Email"
            prepend-icon="mdi-email"
            type="text"
            :error="formErrors.email"
            :error-messages="formErrors.email"
            hint="You can modify or resend an existing invite by simply using the same email again"
          />
          <v-select
            v-model="inputs.role"
            :hint="
              inputs.role
                ? `${inputs.role.title} gets access ${inputs.role.signup_days} days before each game`
                : 'Role determines how many days before each game the player can join.'
            "
            id="role"
            label="Role"
            prepend-icon="mdi-key"
            :items="roles"
            item-text="title"
            item-value="id"
            :error="formErrors.role"
            :error-messages="formErrors.role"
            return-object
            persistent-hint
          />
        </v-form>
        <v-alert
          outlined
          type="error"
          v-for="(error, i) in formErrors.non_field_errors"
          :key="i"
        >
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="requestLoading"
          color="primary"
          @click="saveGroupInvite(inputs)"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Pending Invites -->
    <v-card class="mt-1">
      <v-skeleton-loader
        type="list-item-avatar-two-line"
        class="mx-auto"
        :loading="requestLoading"
      >
        <v-list subheader>
          <v-subheader class="overline">{{
            groupInvites.length ? "Pending Invites" : "No Pending Invites"
          }}</v-subheader>

          <v-list-item v-for="invite in groupInvites" :key="invite.id">
            <v-list-item-avatar color="grey"
              ><v-gravatar :email="invite.email"
            /></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ invite.name }} </v-list-item-title>
              <v-list-item-subtitle>{{ invite.email }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu auto v-if="group.user_is_admin">
                <template v-slot:activator="{ on }">
                  <v-btn text small v-on="on">
                    {{
                      roles.find(obj => {
                        return obj.id === invite.role;
                      }).title
                    }}
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(role, i) in roles"
                    :key="i"
                    :input-value="invite.role.id === role.id"
                    @click="updateInviteRole({ invite, role })"
                  >
                    <v-list-item-title class="text-uppercase caption">
                      {{ role.title }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="askDeleteInvite(invite)">
                    <v-list-item-title class="text-uppercase caption">
                      Remove
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-list-item-action-text v-else>
                {{
                  roles.find(obj => {
                    return obj.id === invite.role;
                  }).title
                }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-skeleton-loader>
    </v-card>
    <div class="my-1"></div>
    <v-btn
      background-color="transparent"
      exact
      :to="{ name: 'GroupDetail', params: { group_id } }"
    >
      {{ isMobile ? "Back" : "Back to Group" }}
    </v-btn>

    <v-dialog v-model="dialogDelete" max-width="290" v-if="invite_to_delete">
      <v-card>
        <v-card-title class="headline">
          Delete {{ invite_to_delete.name }}'s invite?
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete {{ invite_to_delete.name }}'s invite?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDeleteHide">
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDeleteInvite(invite_to_delete)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </OneColumnPage>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "GroupForm",
  data: () => ({
    dialogDelete: false,
    invite_to_delete: null,
    isMobile: false
  }),
  props: ["group_id"],
  computed: {
    ...mapState({
      inputs: state => state.groups.group,
      formErrors: state =>
        state.groups.error ? state.groups.error.response.data : {}
    }),
    ...mapState("groups", [
      "error",
      "requestLoading",
      "roles",
      "group",
      "groupInvites"
    ]),
    ...mapGetters({ groupLoaded: "groups/groupLoaded" })
  },
  methods: {
    ...mapActions("groups", [
      "saveGroupInvite",
      "fetchGroupDetail",
      "fetchRoles",
      "fetchGroupInvites",
      "updateInviteRole",
      "deleteInvite"
    ]),
    askDeleteInvite(invite) {
      this.invite_to_delete = invite;
      this.dialogDelete = true;
    },
    confirmDeleteInvite(invite) {
      this.deleteInvite(invite).then(this.dialogDeleteHide);
    },
    dialogDeleteHide() {
      this.invite_to_delete = null;
      this.dialogDelete = false;
    }
  },
  created() {
    this.isMobile = this.$vuetify.breakpoint.name === "xs";
    this.fetchRoles();
    if (!this.groupLoaded && this.group_id) {
      this.fetchGroupDetail(this.group_id);
    }
    this.fetchGroupInvites(this.group_id);
  }
};
</script>
