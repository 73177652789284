import comments from "@/api/comments";
import {
  REQUEST_START,
  FETCH_COMMENTS,
  REQUEST_ERROR,
  COMMENT_SAVED,
  SET_COMMENTS,
  RESET_COMMENTS,
  COMMENT_DELETED
} from "./types";

const getDefaultState = () => {
  return {
    object_type: "",
    object_pk: "",
    url: "",
    comments: {},
    loadingComments: false,
    requestLoading: false,
    error: false
  };
};

const state = getDefaultState();

const getters = {};

const actions = {
  resetComments({ commit }) {
    commit(RESET_COMMENTS);
  },
  fetchComments({ commit }, { object_type, object_pk, url }) {
    commit(FETCH_COMMENTS);
    return comments
      .fetchComments(object_type, object_pk, url)
      .then(({ data }) =>
        commit(SET_COMMENTS, { data, object_type, object_pk, url })
      );
  },
  saveComment({ commit }, inputs) {
    // if (comment.id) {
    //   commit(REQUEST_START);
    //   return comments
    //     .updateGame(comment)
    //     .then(({ data }) => commit(COMMENT_SAVED, data))
    //     .catch(error => commit(REQUEST_ERROR, error));
    // } else {
    commit(REQUEST_START);
    return comments
      .createComment(inputs)
      .then(({ data }) => commit(COMMENT_SAVED, data))
      .catch(error => {
        commit(REQUEST_ERROR, error);
        // return Promise.reject(error.response);
      });
    // }
  },
  deleteComment({ commit }, comment) {
    commit(REQUEST_START);
    return comments
      .deleteComment(comment)
      .then(() => commit(COMMENT_DELETED, comment))
      .catch(error => {
        commit(REQUEST_ERROR, error);
      });
  }
};

const mutations = {
  [RESET_COMMENTS](state) {
    state = getDefaultState();
  },
  [SET_COMMENTS](state, { data, object_type, object_pk, url }) {
    state.loadingComments = false;
    // set `new=true` for new comments
    if (
      state.object_type == object_type &&
      state.object_pk == object_pk &&
      !!state.comments &&
      data.count > state.comments.count
    ) {
      let i = 0;
      while (i < data.count - state.comments.count) {
        data.results[i].new = true;
        i++;
      }
    }
    // add items to state
    state.object_type = object_type;
    state.object_pk = object_pk;
    state.url = url;
    state.comments = data;
  },
  [FETCH_COMMENTS](state) {
    state.loadingComments = true;
    state.error = false;
  },
  [REQUEST_START](state) {
    state.requestLoading = true;
    state.error = false;
  },
  [REQUEST_ERROR](state, error) {
    state.loadingComments = false;
    state.requestLoading = false;
    state.error = error;
  },
  [COMMENT_DELETED](state, comment) {
    state.requestLoading = false;
    const commentList = [...state.comments.results];
    const index = commentList.indexOf(comment);
    if (index > -1) {
      commentList.splice(index, 1);
      state.comments.results = commentList;
    }
  },
  [COMMENT_SAVED](state, comment) {
    state.requestLoading = false;
    state.error = false;
    // insert comment
    // let index = state.comments.findIndex(v => v.id === comment.id);
    // index > -1 && state.comments.results.splice(index, 1);
    comment.new = true;
    state.comments.results.unshift(comment);
    state.comments.count++;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
