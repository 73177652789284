import auth from "@/api/auth";
import session from "@/api/session";
import {
  AUTH_INITIALIZED,
  LOGIN_BEGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_TOKEN,
  SET_TOKEN,
  REMOVE_CSRFTOKEN,
  SET_CSRFTOKEN,
  SET_USER
} from "./types";

import VueCookies from "vue-cookies";

const TOKEN_STORAGE_KEY = process.env.VUE_APP_TOKEN_STORAGE_KEY;
const isProduction = process.env.NODE_ENV === "production";

const initialState = {
  initialized: false,
  authenticating: false,
  error: false,
  token: null,
  csrftoken: null,
  user: {}
};

const getters = {
  isAuthenticated: (state) => {
    return (
      (isProduction && !!state.csrftoken) || (!isProduction && !!state.token)
    );
  }
};

const actions = {
  login({ dispatch, commit }, { email, password }) {
    commit(LOGIN_BEGIN);
    return auth
      .login(email, password)
      .then(({ data }) => {
        commit(SET_TOKEN, data.key);
        dispatch("checkCookie");
      })
      .then(() => commit(LOGIN_SUCCESS))
      .catch((error) => {
        if (
          error.response &&
          error.response.data.detail &&
          error.response.data.detail.includes("CSRF Failed")
        ) {
          dispatch("logout").then(() => dispatch("login", { email, password }));
        } else {
          commit(LOGIN_FAILURE, error);
        }
      });
  },
  getAccountDetails({ dispatch, commit }) {
    return auth
      .getAccountDetails()
      .then(({ data }) => commit(SET_USER, data))
      .catch(({ response }) => {
        if ([401, 403].includes(response.status)) {
          dispatch("logout");
        } else {
          console.log(response.status, response.statusText);
        }
      });
  },
  logout({ commit }) {
    return auth
      .logout()
      .then(() => commit(LOGOUT))
      .finally(() => {
        commit(REMOVE_TOKEN);
        commit(REMOVE_CSRFTOKEN);
      });
  },
  checkCookie({ commit }) {
    // check session cookie
    const csrftoken = VueCookies.get("csrftoken");
    if (csrftoken) commit(SET_CSRFTOKEN, csrftoken);
  },
  initialize({ state, dispatch, commit }) {
    if (state.initialized) return;

    // check token (dev)
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (token) {
      if (isProduction) {
        commit(REMOVE_TOKEN);
      }
      if (!isProduction) {
        commit(SET_TOKEN, token);
      }
    }

    dispatch("checkCookie");

    commit(AUTH_INITIALIZED);
  }
};

const mutations = {
  [AUTH_INITIALIZED](state) {
    state.initialized = true;
  },
  [LOGIN_BEGIN](state) {
    state.authenticating = true;
    state.error = false;
  },
  [LOGIN_FAILURE](state, error) {
    state.authenticating = false;
    state.error = error;
  },
  [LOGIN_SUCCESS](state) {
    state.authenticating = false;
    state.error = false;
  },
  [LOGOUT](state) {
    state.authenticating = false;
    state.error = false;
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_TOKEN](state, token) {
    if (!isProduction) localStorage.setItem(TOKEN_STORAGE_KEY, token);
    session.defaults.headers.Authorization = `Token ${token}`;
    state.token = token;
    this.dispatch("auth/getAccountDetails");
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    delete session.defaults.headers.Authorization;
    state.token = null;
    state.user = {};
  },
  [SET_CSRFTOKEN](state, csrftoken) {
    state.csrftoken = csrftoken;
    this.dispatch("auth/getAccountDetails");
  },
  [REMOVE_CSRFTOKEN](state) {
    VueCookies.remove("csrftoken");
    state.csrftoken = null;
    state.user = {};
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
