<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <div v-if="successMessage">
          <v-alert type="success">
            {{ successMessage }}
          </v-alert>
        </div>
        <div v-else>
          <v-card class="elevation-12 pb-2">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Reset Password</v-toolbar-title>
            </v-toolbar>
            <v-card-subtitle>
              Back to
              <router-link to="/login">login</router-link>
            </v-card-subtitle>
            <v-card-text>
              <v-form
                @submit.prevent="submit"
                @keyup.native.enter="sendPasswordResetEmail(inputs)"
              >
                <v-text-field
                  v-model="inputs.email"
                  id="email"
                  label="Email"
                  prepend-icon="mdi-account"
                  type="text"
                  :error="formErrors.email"
                  :error-messages="formErrors.email"
                />
              </v-form>
              <v-alert
                outlined
                type="error"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                {{ error }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :loading="emailLoading"
                color="primary"
                @click="sendPasswordResetEmail(inputs)"
                >Request Reset Email</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return { inputs: { email: "" } };
  },
  computed: {
    ...mapState({
      formErrors: state =>
        state.password.emailError ? state.password.emailError.response.data : {}
    }),
    ...mapState("password", [
      "emailCompleted",
      "emailError",
      "emailLoading",
      "successMessage"
    ])
  },
  methods: mapActions("password", [
    "sendPasswordResetEmail",
    "clearResetStatus"
  ]),
  beforeRouteLeave(to, from, next) {
    this.clearResetStatus();
    next();
  }
};
</script>
