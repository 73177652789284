import auth from "@/api/auth";

import {
  AUTH_BEGIN,
  AUTH_CLEAR,
  AUTH_FAILURE,
  AUTH_SUCCESS,
  ACTIVATION_SUCCESS
} from "./types";

export default {
  namespaced: true,
  state: {
    activationCompleted: false,
    error: false,
    loading: false,
    successMessage: ""
  },

  actions: {
    createAccount(
      { dispatch, commit },
      { first_name, last_name, email, password1, password2 }
    ) {
      commit(AUTH_BEGIN);
      return auth
        .createAccount(first_name, last_name, email, password1, password2)
        .then(res => {
          commit(AUTH_SUCCESS, res);
          if (res.data.key)
            commit("auth/SET_TOKEN", res.data.key, { root: true });
          if (res.data.joined_group)
            commit("groups/INVITE_REDEEMED", res.data.joined_group, {
              root: true
            });
        })
        .catch(error => commit(AUTH_FAILURE, error));
    },
    editAccount(
      { dispatch, commit },
      { first_name, last_name, email, new_password1, new_password2 }
    ) {
      commit(AUTH_BEGIN);
      return auth
        .updateAccountDetails(first_name, last_name, email)
        .then(res => {
          console.log(res.data);
          commit(AUTH_SUCCESS, res);
          commit("auth/SET_USER", res.data, { root: true });
        })
        .catch(error => commit(AUTH_FAILURE, error));
    },
    activateAccount({ commit }, { key }) {
      commit(AUTH_BEGIN);
      return auth
        .verifyAccountEmail(key)
        .then(() => commit(ACTIVATION_SUCCESS))
        .catch(error => commit(AUTH_FAILURE, error));
    },
    clearRegistrationStatus({ commit }) {
      commit(AUTH_CLEAR);
    },
    clearActivationStatus({ commit }) {
      commit(AUTH_CLEAR);
    }
  },

  mutations: {
    [AUTH_BEGIN](state) {
      state.loading = true;
      state.successMessage = "";
    },
    [AUTH_CLEAR](state) {
      state.activationCompleted = false;
      state.error = false;
      state.loading = false;
      state.successMessage = "";
    },
    [AUTH_FAILURE](state, error) {
      state.error = error;
      state.loading = false;
      state.successMessage = "";
    },
    [AUTH_FAILURE](state, error) {
      state.error = error;
      state.loading = false;
      state.successMessage = "";
    },
    [AUTH_SUCCESS](state, res) {
      state.error = false;
      state.loading = false;
      state.successMessage = res.data.detail;
    },
    [ACTIVATION_SUCCESS](state) {
      state.activationCompleted = true;
      state.error = false;
      state.loading = false;
      state.successMessage = "";
      this.dispatch("auth/getAccountDetails");
    }
  }
};
