import session from "./session";

// MAKE SURE URLS ALWAYS END IN '/'
export default {
  fetchGames() {
    return session.get(`${process.env.VUE_APP_API_URL}/games/`);
  },
  fetchGameDetail(game_id) {
    return session.get(`${process.env.VUE_APP_API_URL}/games/${game_id}/`);
  },
  createGame(game) {
    return session.post(`${process.env.VUE_APP_API_URL}/games/`, game);
  },
  updateGame(game) {
    return session.put(
      `${process.env.VUE_APP_API_URL}/games/${game.id}/`,
      game
    );
  },
  signup(game, status, user, signup) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/games/${game.id}/signup/`,
      { status, user: user ? user.id : null, signup: signup ? signup.id : null }
    );
  },
  setGuestName(signup) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/games/${signup.game.id}/set_guest_name/`,
      { signup_pk: signup.id, guest_name: signup.guest_name }
    );
  }
};
