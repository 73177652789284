import session from "./session";

// MAKE SURE URLS ALWAYS END IN '/'
export default {
  fetchGroups() {
    return session.get(`${process.env.VUE_APP_API_URL}/groups/`);
  },
  fetchGroupDetail(group_id) {
    return session.get(`${process.env.VUE_APP_API_URL}/groups/${group_id}/`);
  },
  createGroup(group) {
    return session.post(`${process.env.VUE_APP_API_URL}/groups/`, group);
  },
  updateGroup(group) {
    return session.put(
      `${process.env.VUE_APP_API_URL}/groups/${group.id}/`,
      group
    );
  },
  saveGroupInvite(group, name, email, role) {
    return session.post(
      `${process.env.VUE_APP_API_URL}/groups/${group.id}/create_invite/`,
      { name, email, role }
    );
  },
  updateInviteRole(invite, group_id) {
    return session.put(
      `${process.env.VUE_APP_API_URL}/group-invites/${invite.id}/?group=${group_id}`,
      invite
    );
  },
  deleteInvite(invite, group_id) {
    return session.delete(
      `${process.env.VUE_APP_API_URL}/group-invites/${invite.id}/?group=${group_id}`
    );
  },
  fetchGroupInvites(group_id) {
    return session.get(
      `${process.env.VUE_APP_API_URL}/group-invites/?group=${group_id}`
    );
  },
  fetchRoles() {
    return session.get(`${process.env.VUE_APP_API_URL}/roles/`);
  },
  updateMemberRole(member) {
    return session.put(
      `${process.env.VUE_APP_API_URL}/group-members/${member.id}/`,
      member
    );
  },
  deleteMember(member) {
    return session.delete(
      `${process.env.VUE_APP_API_URL}/group-members/${member.id}/`
    );
  }
};
