export const REQUEST_START = "REQUEST_START";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const SET_ROLES = "SET_ROLES";
export const SET_GROUPS = "SET_GROUPS";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUP_INVITES = "SET_GROUP_INVITES";
export const GROUP_SAVED = "GROUP_SAVED";
export const INVITE_SAVED = "INVITE_SAVED";
export const INVITE_REDEEMED = "INVITE_REDEEMED";
export const INVITE_REMOVED = "INVITE_REMOVED";
export const INVITE_UPDATED = "INVITE_UPDATED";
export const MEMBER_REQUEST_START = "MEMBER_REQUEST_START";
export const MEMBER_SAVED = "MEMBER_SAVED";
export const MEMBER_REMOVED = "MEMBER_REMOVED";
