import groups from "@/api/groups";
import {
  SET_ROLES,
  SET_GROUPS,
  SET_GROUP,
  SET_GROUP_INVITES,
  GROUP_SAVED,
  INVITE_SAVED,
  INVITE_REDEEMED,
  INVITE_UPDATED,
  INVITE_REMOVED,
  REQUEST_START,
  REQUEST_ERROR,
  MEMBER_REQUEST_START,
  MEMBER_SAVED,
  MEMBER_REMOVED
} from "./types";
import router from "@/router";

const state = {
  groups: [],
  group: {
    id: "",
    title: "",
    description: ""
  },
  groupInvites: [],
  requestLoading: false,
  error: false,
  roles: []
};

const getters = {
  groupLoaded: state => Boolean(state.group.id)
};

const actions = {
  fetchRoles({ commit }) {
    commit(REQUEST_START);
    return groups
      .fetchRoles()
      .then(({ data }) => commit(SET_ROLES, data))
      .catch(error => commit(REQUEST_ERROR, error));
  },
  fetchGroups({ commit }) {
    commit(REQUEST_START);
    return groups.fetchGroups().then(({ data }) => commit(SET_GROUPS, data));
  },
  fetchGroupDetail({ commit, state, rootState }, group_id) {
    group_id = group_id || state.group.id;
    commit(REQUEST_START);
    return groups
      .fetchGroupDetail(group_id)
      .then(({ data }) => commit(SET_GROUP, data))
      .catch(error => commit(REQUEST_ERROR, error));
  },
  fetchGroupInvites({ commit }, group_id) {
    commit(REQUEST_START);
    return groups
      .fetchGroupInvites(group_id)
      .then(({ data }) => commit(SET_GROUP_INVITES, data))
      .catch(error => commit(REQUEST_ERROR, error));
  },
  saveGroup({ commit }, group) {
    if (group.id) {
      commit(REQUEST_START);
      return groups
        .updateGroup(group)
        .then(({ data }) => commit(GROUP_SAVED, data))
        .catch(error => commit(REQUEST_ERROR, error));
    } else {
      commit(REQUEST_START);
      return groups
        .createGroup(group)
        .then(({ data }) => commit(GROUP_SAVED, data))
        .catch(error => commit(REQUEST_ERROR, error));
    }
  },
  saveGroupInvite({ commit }, { name, email, role }) {
    commit(REQUEST_START);
    let role_id = role ? role.id : null;
    return groups
      .saveGroupInvite(state.group, name, email, role_id)
      .then(({ data }) => commit(INVITE_SAVED, data))
      .catch(error => commit(REQUEST_ERROR, error));
  },
  updateInviteRole({ commit }, { invite, role }) {
    let orig_invite = Object.assign({}, invite);
    invite.role = role.id;
    commit(MEMBER_REQUEST_START);
    return groups
      .updateInviteRole(invite, state.group.id)
      .then(({ data }) => commit(INVITE_UPDATED, data))
      .catch(error => {
        invite.role = orig_invite.role;
        commit(REQUEST_ERROR, error);
      });
  },
  deleteInvite({ commit }, invite) {
    commit(MEMBER_REQUEST_START);
    return groups
      .deleteInvite(invite, state.group.id)
      .then(() => commit(INVITE_REMOVED, invite))
      .catch(error => {
        commit(REQUEST_ERROR, error);
      });
  },
  updateMemberRole({ commit }, { member, role }) {
    let orig_member = Object.assign({}, member);
    member.role = role;
    commit(MEMBER_REQUEST_START);
    return groups
      .updateMemberRole(member)
      .then(({ data }) => commit(MEMBER_SAVED, data))
      .catch(error => {
        member.role = orig_member.role;
        commit(REQUEST_ERROR, error);
      });
  },
  deleteMember({ commit, rootState }, member) {
    commit(MEMBER_REQUEST_START);
    return groups
      .deleteMember(member)
      .then(() => commit(MEMBER_REMOVED, { member, rootState }))
      .catch(error => {
        commit(REQUEST_ERROR, error);
      });
  }
};

const mutations = {
  [SET_ROLES](state, roles) {
    state.requestLoading = false;
    state.roles = roles;
  },
  [SET_GROUPS](state, groups) {
    state.requestLoading = false;
    state.groups = groups;
  },
  [SET_GROUP](state, group) {
    state.requestLoading = false;
    state.group = group;
  },
  [SET_GROUP_INVITES](state, groupInvites) {
    state.requestLoading = false;
    state.groupInvites = groupInvites;
  },
  [REQUEST_START](state) {
    state.requestLoading = true;
    state.error = false;
  },
  [REQUEST_ERROR](state, error) {
    state.requestLoading = false;
    state.memberLoading = false;
    state.error = error;
  },
  [GROUP_SAVED](state, group) {
    state.requestLoading = false;
    state.error = false;
    router.push(`/groups/${group.id}`);
  },
  [INVITE_UPDATED](state, invite) {
    state.memberLoading = false;
    this.dispatch("groups/fetchGroupInvites", state.group.id);
  },
  [INVITE_SAVED](state, invite) {
    state.requestLoading = false;
    state.error = false;
    router.push({
      name: "GroupDetail",
      params: { group_id: state.group.id }
    });
  },
  [INVITE_REDEEMED](state, group_id) {
    sessionStorage.removeItem("invite_code");
    router.push({
      name: "GroupDetail",
      params: { group_id: group_id }
    });
  },
  [MEMBER_REQUEST_START](state, member) {
    state.memberLoading = false;
    state.error = false;
  },
  [MEMBER_SAVED](state, member) {
    state.memberLoading = false;
    state.error = false;
  },
  [MEMBER_REMOVED](state, { member, rootState }) {
    state.memberLoading = false;
    state.error = false;
    const members = [...state.group.members];
    const index = members.indexOf(member);
    if (index > -1) {
      members.splice(index, 1);
      state.group.members = members;
    }
    if (rootState.auth.user.pk == member.user.id) {
      router.push({
        name: "Groups"
      });
    }
  },
  [INVITE_REMOVED](state, invite) {
    state.memberLoading = false;
    state.error = false;
    const groupInvites = [...state.groupInvites];
    const index = groupInvites.indexOf(invite);
    if (index > -1) {
      groupInvites.splice(index, 1);
      state.groupInvites = groupInvites;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
