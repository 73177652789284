<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <div v-if="successMessage">
          <v-alert type="success">
            {{ successMessage }}
          </v-alert>
        </div>
        <div v-else>
          <v-card class="elevation-12 pb-2">
            <v-toolbar color="primary" flat>
              <v-toolbar-title>Register</v-toolbar-title>
            </v-toolbar>
            <v-card-subtitle>
              Already have an account?
              <router-link to="/login">Login</router-link>
            </v-card-subtitle>
            <v-card-text>
              <v-form
                @submit.prevent="submit"
                @keyup.native.enter="createAccount(inputs)"
              >
                <v-text-field
                  v-model="inputs.first_name"
                  id="first-name"
                  label="First Name"
                  prepend-icon="mdi-account"
                  type="text"
                  :error="formErrors.first_name"
                  :error-messages="formErrors.first_name"
                />
                <v-text-field
                  v-model="inputs.last_name"
                  id="last-name"
                  label="Last Name"
                  prepend-icon="mdi-account"
                  type="text"
                  :error="formErrors.last_name"
                  :error-messages="formErrors.last_name"
                />
                <v-text-field
                  v-model="inputs.email"
                  id="email"
                  label="Email"
                  prepend-icon="mdi-email"
                  type="text"
                  :error="formErrors.email"
                  :error-messages="formErrors.email"
                />
                <v-text-field
                  v-model="inputs.password1"
                  id="password1"
                  label="Password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :error="formErrors.password1"
                  :error-messages="formErrors.password1"
                />
                <v-text-field
                  v-model="inputs.password2"
                  id="password2"
                  label="Repeat Password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :error="formErrors && formErrors.password2"
                  :error-messages="formErrors.password2"
                />
              </v-form>
              <v-alert
                outlined
                type="error"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                {{ error }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                :loading="loading"
                color="primary"
                @click="createAccount(inputs)"
                >Register</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      inputs: {
        first_name: "",
        last_name: "",
        email: "",
        password1: "",
        password2: ""
      }
    };
  },
  created() {
    // store away invite code into session storage
    let query = Object.assign({}, this.$route.query);
    let inviteCode = query.invite_code;
    if (inviteCode) {
      sessionStorage.invite_code = inviteCode;
      delete query.invite_code;
      this.$router.replace({ query });
    }
  },
  computed: {
    ...mapState({
      formErrors: state => {
        if (state.signup.error) {
          try {
            return state.signup.error.response.data;
          } catch (err) {
            return {
              non_field_errors: [
                "Something ain't jellin' right now. Might need to try again later."
              ]
            };
          }
        } else {
          return {};
        }
      }
    }),
    ...mapState("signup", ["error", "loading", "successMessage"])
  },
  methods: mapActions("signup", ["createAccount", "clearRegistrationStatus"]),
  beforeRouteLeave(to, from, next) {
    this.clearRegistrationStatus();
    next();
  }
};
</script>
