export const statusArgs = {
  in: "",
  maybe: "maybe",
  out: "out"
};
export const statusColors = {
  confirmed: "green",
  waitlisted: "amber darken-3",
  maybe: "amber",
  out: "grey"
};
export const statusIcons = {
  confirmed: "mdi-check-bold",
  waitlisted: "mdi-clock-outline",
  maybe: "mdi-help-circle-outline",
  out: "mdi-close-thick"
};
