import auth from "@/api/auth";

import {
  PASSWORD_RESET_BEGIN,
  PASSWORD_RESET_CLEAR,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_EMAIL_BEGIN,
  PASSWORD_EMAIL_CLEAR,
  PASSWORD_EMAIL_FAILURE,
  PASSWORD_EMAIL_SUCCESS
} from "./types";

const getDefaultState = () => {
  return {
    emailCompleted: false,
    emailError: false,
    emailLoading: false,
    resetCompleted: false,
    resetError: null,
    resetLoading: false,
    successMessage: ""
  };
};

const state = getDefaultState();

const actions = {
  resetPassword({ commit }, { uid, token, password1, password2 }) {
    commit(PASSWORD_RESET_BEGIN);
    return auth
      .resetAccountPassword(uid, token, password1, password2)
      .then(res => commit(PASSWORD_RESET_SUCCESS, res))
      .catch(error => commit(PASSWORD_RESET_FAILURE, error));
  },
  sendPasswordResetEmail({ commit }, { email }) {
    commit(PASSWORD_EMAIL_BEGIN);
    return auth
      .sendAccountPasswordResetEmail(email)
      .then(res => commit(PASSWORD_EMAIL_SUCCESS, res))
      .catch(error => commit(PASSWORD_EMAIL_FAILURE, error));
  },
  clearResetStatus({ commit }) {
    commit(PASSWORD_RESET_CLEAR);
  }
};

const mutations = {
  [PASSWORD_RESET_BEGIN](state) {
    state.resetLoading = true;
    state.successMessage = "";
  },
  [PASSWORD_RESET_CLEAR](state) {
    Object.assign(state, getDefaultState());
  },
  [PASSWORD_RESET_FAILURE](state, error) {
    state.resetLoading = false;
    state.resetError = error;
  },
  [PASSWORD_RESET_SUCCESS](state, res) {
    state.resetCompleted = true;
    state.resetError = false;
    state.resetLoading = false;
    state.successMessage = res.data.detail;
  },
  [PASSWORD_EMAIL_BEGIN](state) {
    state.emailLoading = true;
    state.successMessage = "";
  },
  [PASSWORD_EMAIL_CLEAR](state) {
    state.emailCompleted = false;
    state.emailError = false;
    state.emailLoading = false;
    state.successMessage = "";
  },
  [PASSWORD_EMAIL_FAILURE](state, error) {
    state.emailError = error;
    state.emailLoading = false;
  },
  [PASSWORD_EMAIL_SUCCESS](state, res) {
    state.emailCompleted = true;
    state.emailError = false;
    state.emailLoading = false;
    state.successMessage = res.data.detail;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
