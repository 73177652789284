<template>
  <OneColumnPage>
    <h1>Page Not Found</h1>
    <v-btn color="primary" to="/" class="mt-3">Take me home</v-btn>
  </OneColumnPage>
</template>

<script>
export default {
  name: "Lost"
};
</script>
