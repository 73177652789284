import session from "./session";

// MAKE SURE URLS ALWAYS END IN '/'
export default {
  fetchComments(object_type, object_pk, url) {
    if (url) return session.get(url);
    return session.get(
      `${process.env.VUE_APP_API_URL}/comments/?object_type=${object_type}&object_pk=${object_pk}`
    );
  },
  createComment({ comment, object_type, object_pk }) {
    return session.post(`${process.env.VUE_APP_API_URL}/comments/`, {
      comment,
      object_type,
      object_pk
    });
  },
  updateComment(comment) {
    return session.put(
      `${process.env.VUE_APP_API_URL}/comments/${comment.id}/`,
      comment
    );
  },
  deleteComment(comment) {
    return session.delete(
      `${process.env.VUE_APP_API_URL}/comments/${comment.id}/`
    );
  }
};
